<template>
  <div class="trip-content">
    <div >
      <!-- tabs -->
      <div
        class="custom-tabs__tabs d-flex align-center justify-space-start mt-10"
      >
        <div
          class="
            custom-tabs__tabs__tab
            w-30
            text-center
            cursor-pointer
            mr-3
            px-6
            pt-2
          "
          :class="tab.active ? 'custom-tabs__tabs__tab--active' : ''"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="tabClicked(tab)"
        >
          <span
            class="font-25"
            :class="tab.active ? 'halfwhite--text' : 'primary--text'"
            >{{ tab.title }}</span
          >
        </div>
      </div>

      <!-- items -->
      <transition name="fade" mode="out-in">
        <v-row v-if="loading" class="mt-2">
          <v-col cols="12" md="4" v-for="n in 3" :key="n">
            <skeleton-card />
          </v-col>
        </v-row>
        <v-row class="mt-4" :key="keyUpdated">
          <v-col
            class="relative"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            v-for="(item, i) in data"
            :key="i"
            style="flex-direction: column"
          >
            <product-card
            class="py-1"
              :item="item"
              :route="
                typePage == '/shipments'
                  ? userID == item.owner_id
                    ? '/myshipment/details/' + item.id
                    : '/shipment-details/' + item.id
                  : userID == item.owner_id
                  ? '/myProduct/details/' + item.id
                  : '/buy&ship/' + item.id
              "
            >
            </product-card>
          </v-col>
        </v-row>
      </transition>

      <!-- if empty -->
      <no-items v-if="!loading && data.length == 0"></no-items>

      <!-- if loading -->
    </div>
    <!-- {{ data }} -->
  </div>
</template>

<script>
import ProductCard from "../../../components/core/ProductCard.vue";
export default {
  components: {
    ProductCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: (vm) => ({
    tabs: [
      {
        title: vm.$t("onlyShip"),
        active: true,
        api: "/shipments",
      },
      {
        title: vm.$t("buyShip"),
        active: false,
        api: "/onlineOrders",
      },
    ],
    typePage: "/shipments",
    keyUpdated: false,
  }),
  computed: {
    userID() {
      return localStorage.getItem("userID");
    },
  },
  methods: {
    tabClicked(tab) {
      this.typePage = tab.api;
      this.$emit("tabChanged", tab);
      this.tabs.forEach((t) => {
        if (t.title == tab.title) {
          t.active = true;
        } else {
          t.active = false;
        }
      });
      this.keyUpdated = !this.keyUpdated;
    },
  },
};
</script>

<style lang="scss">
.trip-content {
}
</style>
