<template>
  <!-- <v-container> -->
    <div class="trips-head mt-10">

      <div class="max-width">
        <h2 class="my-5 primary--text">{{$t('shipments')}}</h2>

        <!-- filters -->
        <div class="d-flex align-center justify-space-between ">
          <v-row align="center">
            <v-col cols="12" md="3" sm="6" xs="12" >
              <!-- to -->
              <base-select
                :items="countriesFilter"
                :placeholder="$t('toCountry')"
                :rules="[]"
                v-model="filter.to"
                itemValue="name"
                itemText="name"
                @input="searchHandler"
              ></base-select>
            </v-col>

            <v-col cols="12" md="3" sm="6" xs="12" >
              <!-- category -->
              <base-select
                :placeholder="$t('category')"
                :rules="[]"
                :items="categoriesFilter"
                v-model="filter.category"
                itemValue="id"
                itemText="name"
                @input="searchHandler"
              ></base-select>
            </v-col>

            <v-col cols="12" md="6" xs="12" class="ml-auto">
              <!-- add new -->
              <div class="d-flex justify-end w-100  font-25">
                <v-btn
                  class="px-1"
                  color="primary"
                  elevation="0"
                  height="38px"
                  min-width="280"
                  @click="
                    tab == '/shipments'
                      ? $router.push('/add-shipment')
                      : $router.push('/buy-product/offline')
                  "
                  ><span class="font-25 mx-1">+</span>
                  <span class="font-300 font-20 white--text">{{
                    tab == "/shipments"
                      ? $t("addNewShipment")
                      : $t("addNewProduct")
                  }}</span></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  <!-- </v-container> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    tab: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    filter: {},
  }),
  computed: {
    ...mapGetters(["countriesFilter", "categoriesFilter"]),
  },
  methods: {
    searchHandler() {
      this.$emit("searchHandler", this.filter);
    },
  },
};
</script>

<style lang="scss">
</style>
