<template>
  <div class="trips-page">
    <v-container>
      <shipment-head
        @searchHandler="searchHandler"
        :tab="tab"
        :key="tab"
      ></shipment-head>
        <shipment-content
          :data="items"
          :isSearch="isSearch"
          @tabChanged="tabChangedHandler"
          @openPlaceDialog="openPlaceDialog"
          :loading="loading"
        ></shipment-content>

      <!-- ship dialog -->
      <v-dialog v-model="bidDialog" max-width="400" content-class="white">
        <place-bid
          @next="nextHandler"
          :key="bidDialog"
          @cancel="bidDialog = false"
        ></place-bid>
      </v-dialog>

      <!-- ship dialog -->
      <v-dialog v-model="waitDialog" max-width="400" content-class="white">
        <shipment-wait @close="$router.replace('/')"></shipment-wait>
      </v-dialog>

      <!-- pagination -->
      <div class="max-width mt-10">
        <div class="d-flex justify-center">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="isSearch ? searchHandler(filter, $event) : getData($event)"
            color="primary"
          ></v-pagination>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import ShipmentHead from "./components/HeadFilters.vue";
import ShipmentContent from "./components/Content.vue";
import PlaceBid from "../../components/dialogs/PlaceBid.vue";
import ShipmentWait from "../../components/dialogs/ShipmentWait.vue";
export default {
  components: {
    ShipmentHead,
    ShipmentContent,
    PlaceBid,
    ShipmentWait,
  },
  data: () => ({
    isSearch: false,
    items: [],
    itemsCopy: [],
    bidDialog: false,
    waitDialog: false,
    tab: "/shipments",

    totalPages: 0,
    page: 1,
    filter: {},
    loading: false,
  }),
  methods: {
    openPlaceDialog(item) {
      this.shipment_id = item.id;
      this.bidDialog = true;
    },
    async nextHandler(value) {
      let payload = {
        shipment_id: this.shipment_id,
        price_shipment: value.price_shipment,
        trip_id: value.trip_id,
        url: "/sendBidAsBringer",
      };
      console.log( this.shipment_id)

      let res = await this.$store.dispatch("bidSubmit", payload);
      if (res) {
        this.bidDialog = false;
        this.waitDialog = true;
        await this.$messaging().getToken({
          vapidKey: localStorage.getItem("token"),
        });
      }
    },
    async submitBid() {},
    async searchHandler(filter, number = 1) {
      this.filter = { ...filter };
      let { data } = await this.$axios.get(
        `${this.tab}?page=${number}${
          this.filter.from && this.filter.from != "All"
            ? "&from=" + this.filter.from
            : ""
        }${
          this.filter.to && this.filter.to != "All"
            ? "&to=" + this.filter.to
            : ""
        }${
          this.filter.category && this.filter.category != "All"
            ? "&category_id=" + this.filter.category
            : ""
        }
        `
      );
      if (data.data) {
        this.isSearch = true;
        if (this.tab == "/onlineOrders") {
          this.items = await this.$store.dispatch(
            "returnOnlineProductsArray",
            data.data
          );
        } else {
          this.items = await this.$store.dispatch(
            "returnShipmentArray",
            data.data
          );
        }
        this.page = number;
        this.totalPages = Math.ceil(data.data.total / data.data.per_page);
      }
    },
    tabChangedHandler(tab) {
      this.tab = tab.api;
      this.fetchData(tab);
    },
    async fetchData(tab) {
      this.loading = true;
      let { data } = await this.$axios.get(tab.api);
      if (data && tab.api == "/shipments") {
        this.items = await this.$store.dispatch(
          "returnShipmentArray",
          data.data
        );
      } else {
        this.items = await this.$store.dispatch(
          "returnOnlineProductsArray",
          data.data
        );
      }
      this.loading = false;
      this.itemsCopy = [...this.items];
    },
  },
  created() {
    let tab = { api: "/shipments" };
    this.fetchData(tab);
  },
};
</script>

<style></style>
